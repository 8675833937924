import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Toolbar, Badge, Hidden, IconButton, Button, FormControlLabel, Checkbox, Popover } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import FilterIcon from '@material-ui/icons/FilterList';
import { CurrentPageName } from '../Sidebar/Sidebar';
import { useState } from 'react';
import CollapsableAppBar from './CollapsableAppBar';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from 'components';
import { configActions } from '_actions/config.actions';
import { leadConstants } from '_constants';
import { useLocation } from 'react-router';
import MessagesSync from 'views/Message/components/MessagesList/MessagesSync';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 35,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  drawer: {
    maxWidth: 440,
    
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      height: 'calc(100%)'
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  fabProgress: {
    color: "#FFFFFF",
  },
  searchInput: {
    width: '20px'
  }
}));

const Topbar = props => {

  const { className, onSidebarOpen,onSidebarRightOpen, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const activeSearchBox = useSelector(state => state.config.activeSearchBox);

  useEffect(() => {
    const sts = query.get('ls');
    if (sts) {
      dispatch(
        configActions.setLeadSearchStatuses([sts])
      );
    }
  }, []);

  const leadSearchText = useSelector(state => state.config.leadSearchText);
  const leadSearchStatuses = useSelector(state => state.config.leadSearchStatuses);



  
  

  // text
  const [searchText, setSearchText] = useState('');

  const handleLeadSearchTextChange = event => {
    setSearchText(event.target.value);
  };
  const handleLeadSearchTextKeyPress = event => {
    if (event.type === 'keypress' && event.key === 'Enter') {
      dispatch(configActions.setLeadSearchText(searchText));
    }
  };




  useEffect(() => {
    const sts = query.get('ms');
    if (sts) {
      dispatch(configActions.setMessageSearchStatuses([sts]));
    }
  }, []);

  const messageSearchText = useSelector(state => state.config.messageSearchText);
  const messageSearchStatuses = useSelector(state => state.config.messageSearchStatuses);

  const handleMessageSearchTextChange = event => {
    dispatch(configActions.setMessageSearchText(event.target.value));
  };

  const handleMessageSearchStatusChange = event => {

    const checked = event.target.checked;
    const value = event.target.value;
    let sts = [...messageSearchStatuses];

    if (sts.indexOf(value) >= 0) {
      sts.splice(sts.indexOf(value), 1);
    }

    if (checked) {
      sts.push(value);
    }

    dispatch(configActions.setMessageSearchStatuses(sts));
  };

  const getMessageStautsChecked = (status) => {
    return messageSearchStatuses.includes(status);
  }


  

  // message popover

  const [msAnchorEl, setMsAnchorEl] = React.useState(null);


  const msHandleClick = (event) => {
    setMsAnchorEl(event.currentTarget);
  };

  const msHandleClose = () => {
    setMsAnchorEl(null);
  };

  const msOpen = Boolean(msAnchorEl);
  const msId = msOpen ? 'ms-popover' : undefined;

  useEffect(()=> {
    setSearchText(leadSearchText);
  }, [leadSearchText]);

  return (
    <CollapsableAppBar>
      <Toolbar disableGutters={true}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <CurrentPageName></CurrentPageName>
        <div className={classes.flexGrow} />

        <MessagesSync ></MessagesSync>

      </Toolbar>
      {/* <Toolbar disableGutters={true} style={{ minHeight: "auto", backgroundColor: "#f5f5f5", color: "black" }}>

        {activeSearchBox === 'lead' && (
          <Fragment>
           
            
            <SearchInput
              
              className={classes.searchInput}
              placeholder="Search"
              // placeholder="Search Lead"
              onChange={handleLeadSearchTextChange}
              onKeyPress={handleLeadSearchTextKeyPress}
              name="search"
              value={searchText}
            />


          </Fragment>
        )}

        {/* {activeSearchBox === 'message' && (
          <Fragment>
            <SearchInput
              className={classes.searchInput}
              placeholder="Search Message"
              onChange={handleMessageSearchTextChange}
              name="search"
              value={messageSearchText}

            />
            <StyledBadge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              badgeContent={messageSearchStatuses.length} color="primary">
              <Button aria-describedby={msId} color="" onClick={msHandleClick}>
                Status
                </Button>
            </StyledBadge>
            <Popover
              id={msId}
              open={msOpen}
              anchorEl={msAnchorEl}
              onClose={msHandleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >


              <FormControlLabel control={<Checkbox
                onChange={handleMessageSearchStatusChange}
                value={leadConstants.LEAD_STATUS_NEW_MESSAGE}
                checked={getMessageStautsChecked(leadConstants.LEAD_STATUS_NEW_MESSAGE)}
                color="primary" />}
                label="New" />
              <FormControlLabel control={<Checkbox
                onChange={handleMessageSearchStatusChange}
                value={leadConstants.LEAD_STATUS_SPAM}
                checked={getMessageStautsChecked(leadConstants.LEAD_STATUS_SPAM)}
                color="primary" />}
                label="Spam" />
              <FormControlLabel control={<Checkbox
                onChange={handleMessageSearchStatusChange}
                value={leadConstants.LEAD_STATUS_AUTO_SPAM}
                checked={getMessageStautsChecked(leadConstants.LEAD_STATUS_AUTO_SPAM)}
                color="primary" />}
                label="AutoSpam" />
              <FormControlLabel control={<Checkbox
                onChange={handleMessageSearchStatusChange}
                value={leadConstants.LEAD_STATUS_AUTO_REPLY}
                checked={getMessageStautsChecked(leadConstants.LEAD_STATUS_AUTO_REPLY)}
                color="primary" />}
                label="AutoReply" />
              <FormControlLabel control={<Checkbox
                onChange={handleMessageSearchStatusChange}
                value={leadConstants.LEAD_STATUS_NEW}
                checked={getMessageStautsChecked(leadConstants.LEAD_STATUS_NEW)}
                color="primary" />}
                label="Lead" />
            </Popover>
          </Fragment>
        )} * /}

        <div className={classes.flexGrow} />
        {activeSearchBox === 'lead' && (
        <StyledBadge badgeContent={leadSearchStatuses.length} color="primary">
          <IconButton
            color="inherit"
            onClick={onSidebarRightOpen}
          ><FilterIcon fontSize="default" />
          </IconButton>
        </StyledBadge>
        )}
        
      </Toolbar> */}
    </CollapsableAppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
