import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardActions, CardContent, CardHeader, Link } from '@material-ui/core';
import querystring from 'querystring';



const optionsEmpty = {
    chart: {
        type: "radialBar",
    },

    // series: [67],
    colors: ["#eee"],
    plotOptions: {
        radialBar: {
            startAngle: -100,
            endAngle: 100,
            dataLabels: {
                name: {
                    offsetY: -10,
                    color: "#ccc",
                    fontSize: "10px"
                },
                value: {
                    color: "#eee",
                    show: true,
                    formatter: (v) => '-',
                }
            }
        }
    },
    labels: ['loading']
}

const TotalRadial = props => {
    const {
        href,
        hrefQs,
        loaded,
        title = '---',
        value,
        percent = '--',
        color = "",
        fontSizeName = "20px",
        fontSizeValue = "14px"
    } = props;

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState();
    const [chartKey, setChartKey] = React.useState(0);

    useEffect(() => {
        if (!loaded) return;
        const roundedPercValue = Math.round((percent + Number.EPSILON) * 100) / 100;

        setChartKey((prevKey) => prevKey + 1);
        setSeries([roundedPercValue]);
        setOptions({
            chart: {
                type: "radialBar",
            },

            colors: [color],
            plotOptions: {
                radialBar: {
                    startAngle: -100,
                    endAngle: 100,
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#000",
                            fontSize: fontSizeName
                        },
                        value: {
                            color: color,
                            fontSize: fontSizeValue,
                            show: true,
                            formatter: (v) => (loaded && value !== undefined) ? value + " leads" : 'loading',
                        }
                    }
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: [roundedPercValue + "%"]
        });
    }, [loaded, value]);

    return (
        <Card style={{ height: "100%" }}>
            <CardContent>
                <CardHeader title={title}  action={null} />
                {loaded ?
                    (
                        <ReactApexChart
                            type='radialBar'
                            key={chartKey}
                            options={options}
                            series={series}
                        />
                    )
                    :
                    (
                        <ReactApexChart
                            type='radialBar'
                            key={chartKey}
                            options={optionsEmpty}
                            series={[100]}
                        />
                    )
                }
            </CardContent>
            <CardActions 
            
            >
                <Box padding={2} width={"100%"} display="flex" justifyContent={"right"}>
                    {href && (<Link variant='button' color='textSecondary' href={href + '?' + querystring.stringify(hrefQs)}>view all</Link>)}
                </Box>
            </CardActions>
        </Card>
    )
};


export default TotalRadial;
