import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { leadActions } from '_actions';
import { leadConstants, messageConstants } from '_constants';
import { Backdrop, Badge, Chip, CircularProgress, TablePagination, Typography, useMediaQuery } from '@material-ui/core';
import ItemList from 'common/ItemList';
import { ZnDepartmentValue } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserValue } from 'common/znFields/ZnUserSelect';
import moment from 'moment';
import FaceIcon from '@material-ui/icons/Face';
import { configActions } from '_actions/config.actions';
import { FaceOutlined,  MailOutlined } from '@material-ui/icons';
import LeadsToolbar from './LeadsToolbar';
import SidebarRight from './SidebarRight';
import theme from 'theme';
import LeadStatusChip from '../LeadsDetail/LeadStatusChip';




const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",

  },
  content: {
    marginTop: theme.spacing(0)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));


const LeadList = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const leads = useSelector(state => state.leads);
  const loading = useSelector(state => state.leads.loading);
  const leadReload = useSelector(state => state.config.leadReload);

  const page = useSelector(state => state.config.leadSearchPage);
  const rowsPerPage = useSelector(state => state.config.leadSearchRowsPerPage);

  const handlePageChange = (event, page) => {
    dispatch(configActions.setLeadPage(page));
  };

  const handleRowsPerPageChange = event => {
    dispatch(configActions.setLeadRowsPerPage(event.target.value));
  };

  useEffect(() => {
    dispatch(leadActions.getAll( page + 1, rowsPerPage,));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if(!leadReload) return;
    dispatch(leadActions.getAll( page + 1, rowsPerPage,));
  }, [ leadReload]);




 

  const leadMapper = (lead) => {
    

    const containsMessageOutgoing = () => {
      return (lead.messages.filter(function(e) { 
        return e.message_status === messageConstants.STATUS_OUTGOING || e.message_status === messageConstants.STATUS_DRAFT; 
      }).length > 0);
    }

    const containsMessageErrors = () => {
      return (lead.messages.filter(function(e) { 
        return e.message_status === messageConstants.STATUS_ERROR;
      }).length > 0);
    }

    const getIcon = () =>{
      if (containsMessageErrors()) {
        return (
          <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left'}} badgeContent="!" color="primary" >
            <FaceOutlined fontSize="large"  />
          </Badge>);
      }



      return (<FaceOutlined fontSize="large" />);
    }

    if (
        lead.status == leadConstants.LEAD_STATUS_NEW_MESSAGE ||
        lead.status == leadConstants.LEAD_STATUS_NEW ||
        lead.status == leadConstants.LEAD_STATUS_SPAM ||
        lead.status == leadConstants.LEAD_STATUS_AUTO_SPAM ||
        lead.status == leadConstants.LEAD_STATUS_AUTO_REPLY 
        ) {
      const message = lead.messages[lead.messages.length -1];

      if (message) {
        return {
          id: lead.id,
          icon: <MailOutlined fontSize="large" />,
          action: "/leads/" + lead.id,
          primary:   <Typography component="h5" variant="h5" ><LeadStatusChip status={lead.status}/>&nbsp;&nbsp;&nbsp;{message.subject}</Typography>,
          secondary: <Fragment>{message.senderName + " <" + message.senderEmail + ">"}<br/>
          {"#" + lead.id + " - ("  + lead.messages.length + ")" + " - created " + moment(message.created_at).fromNow()
          }</Fragment>,
        };
      } else {
        return null;
      }

    } else {

      return {
        id: lead.id,
        icon: getIcon(),
        action: "/leads/" + lead.id,
        primary:   <Typography component="h5" variant="h5" ><LeadStatusChip status={lead.status}/>&nbsp;&nbsp;&nbsp;{lead.name + " <" + lead.email + ">"}</Typography>,
        secondary: <Fragment><ZnDepartmentValue value={lead.department} />&nbsp;-&nbsp;<ZnUserValue value={lead.user} /><br />
        {"#" + lead.id + " - ("  + lead.messages.length + ")" + " - created " + moment(lead.created_at).fromNow()
        }</Fragment>,
      };
    }
  }

  const getOpacity = () => {
    return loading ? '0.5' : '100';
  }

  
 

  return (
    <div className={classes.root}>
      <div className={classes.content} style={{opacity: getOpacity()}} >
        <LeadsToolbar ></LeadsToolbar>
        <ItemList icon={(<FaceIcon />)} items={leads.items} itemMapper={leadMapper}  actionPath="/leads/" />
      </div>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      { (leads.items && leads.items.length > 0) && (
        <TablePagination
          component="div"
          count={leads.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        />
      )}

    
    </div>
  );
};

export default LeadList;



