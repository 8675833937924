import { customerConstants } from '../_constants';
import { customerService } from '../_services';
import { alertActions } from './alert.actions';

export const customerActions = {
  getById,
  getAll,
  save,
  _delete,
  _import,
  _export,
};

function _delete(id, text = '') {
  return dispatch => {
    dispatch(request());

    customerService._delete(id).then(
      cus => dispatch(getAll(text)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: customerConstants.DELETE_REQUEST };
  }
  function failure(error) {
    return { type: customerConstants.DELETE_FAILURE, id, error };
  }
}

function save(customer) {
  return dispatch => {
    dispatch(request());

    return customerService
      .doAction(customer.id, customerConstants.ACTION_SAVE, customer)
      .then(
        result => {
          dispatch(alertActions.success('Customer Saved'));
          dispatch(success(result.data));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: customerConstants.KICK_REQUEST };
  }
  function success(customer) {
    return { type: customerConstants.KICK_SUCCESS, customer };
  }
  function failure(error) {
    return { type: customerConstants.KICK_FAILURE, error };
  }
}

function getAll(text = '', tag = '', source = '', from = '', to = '', page = '', per_page = '', orderBy = '', orderDirection = '') {
  return dispatch => {
    dispatch(request());

    customerService.getAll(text, tag, source, from, to, page, per_page, orderBy, orderDirection).then(
      customers => dispatch(success(customers.data, customers.meta)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: customerConstants.GETALL_REQUEST };
  }
  function success(customers, meta) {
    return { type: customerConstants.GETALL_SUCCESS, customers, meta };
  }
  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error };
  }
}

function getById(id) {
  return dispatch => {
    dispatch(request());

    customerService.getById(id).then(
      customers => dispatch(success(customers.data, customers.meta)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: customerConstants.GETONE_REQUEST };
  }
  function success(customers, meta) {
    return { type: customerConstants.GETONE_SUCCESS, customers, meta };
  }
  function failure(error) {
    return { type: customerConstants.GETONE_FAILURE, error };
  }
}

function _import(data) {
  return dispatch => {
    dispatch(request());

    customerService
      ._import(data)
      .then(
        result => {
          dispatch(success(result.data));
          dispatch(getAll());

        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: customerConstants.IMPORT_REQUEST };
  }
  function success(customer) {
    return { type: customerConstants.IMPORT_SUCCESS, customer };
  }
  function failure(error) {
    return { type: customerConstants.IMPORT_FAILURE, error };
  }
}

function _export(text = '', tag = '', from = '', to = '', page = '', per_page = '', orderBy = '', orderDirection = '') {

  return dispatch => {
    dispatch(request());

    customerService
      .exportCustomers(text, tag, from, to, page, per_page, orderBy, orderDirection)
      .then(
        result => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: customerConstants.EXPORT_REQUEST };
  }
  function success() {
    return { type: customerConstants.EXPORT_SUCCESS };
  }
  function failure(error) {
    return { type: customerConstants.EXPORT_FAILURE, error };
  }
}

