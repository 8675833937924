import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, AppBar, Toolbar } from '@material-ui/core';
import theme from 'theme';
import { leadConstants } from '_constants';
import { green } from '@material-ui/core/colors';
import LeadActionModal from './LeadActionModal';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  content: {
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  btnSuccess: {
    color: green[500]
  }
}));

const SuccessButton = withStyles(theme => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);


const StatusInfo = props => {

  const { title, message } = props;

  return (
    <div>
      <Typography style={{ textTransform: "uppercase" }} variant="h4">{title}</Typography>
    </div>
  );
}

const LeadActions = props => {

  const { className, lead, onStatusChange, setShowMailForm, ...rest } = props;

  const classes = useStyles();

  const handleConfirmAction = (e, action, data) => {
    setConfirmOpened(false);
    onStatusChange(e, lead, action, data);
  };

  const handleClick = (e, lead, actions) => {

    setConfirmAction(leadConstants.LEAD_STATUS_MESSAGES[lead.status].actions);
    setConfirmOpened(true);
  };

  const handlePrevStateClick = (e, lead, actions) => {

    setDefaultMessage('This will set the lead to the previous status !');

    setConfirmAction([leadConstants.LEAD_ACTION_SET_PREVIOUS_STATUS]);
    setConfirmOpened(true);
  };


  // const [formEdit, setFormEdit] = useState(false);
  const sendMailClick = (e, lead, actions) => {
    setShowMailForm(true);
  };


  const handleClose = () => {
    setConfirmOpened(false);
    setTimeout(() => {
      setDefaultMessage(null);
    }, 200);
  }


  const buttons = function () {

    if (!lead) return "";

    return (
      <div className={classes.content}>
        {lead.status !== leadConstants.LEAD_STATUS_NEW_MESSAGE && (
          <Button variant="contained" onClick={(e) => handlePrevStateClick(e, lead, "")}>
            PREV STATUS
          </Button>
        )}
        {leadConstants.LEAD_STATUS_NEW_MESSAGE === lead.status && (
          <Button variant="contained" color="primary" onClick={(e) => handleClick(e, lead, "")}>
            CHECKIN
          </Button>
        )}
        {leadConstants.LEAD_STATUS_CHECKED === lead.status && (
          <Button variant="contained" color="primary" onClick={(e) => sendMailClick(e, lead, "")}>
            REPLY
          </Button>
        )}
        {leadConstants.LEAD_STATUS_SPAM === lead.status && (
          <Button variant="contained" onClick={(e) => handleClick(e, lead, "")}>
            RESET
          </Button>)}
        {leadConstants.LEAD_STATUS_NEW === lead.status && (
          <SuccessButton variant="contained" color="primary" onClick={(e) => handleClick(e, lead, "")}>
            CHECKIN ppp
          </SuccessButton>
        )}
        {leadConstants.LEAD_STATUS_FIRST_CONTACT === lead.status && (
          <Fragment>
            <SuccessButton variant="contained" color="primary" onClick={(e) => handleClick(e, lead, "")}>
              CLOSE
            </SuccessButton>
            <Button variant="contained" color="primary" onClick={(e) => sendMailClick(e, lead, "")}>
              NEW MAIL
            </Button>
          </Fragment>
        )}
        {leadConstants.LEAD_STATUS_OK === lead.status && (
          <SuccessButton variant="contained" color="primary" onClick={(e) => handleClick(e, lead, "")}>
            ELIBRA
          </SuccessButton>
        )}
        {leadConstants.LEAD_STATUS_ERP === lead.status && (
          <SuccessButton variant="contained" color="primary" onClick={(e) => handleClick(e, lead, "")}>
            MATTER COMPLETED
          </SuccessButton>
        )}
      </div>
    )
  }

  const [confirmOpened, setConfirmOpened] = useState(false);
  const [confirmAction, setConfirmAction] = useState([]);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [defaultMessage, setDefaultMessage] = useState(null);

  useEffect(() => {
    if (!lead) return;

    setTitle(leadConstants.LEAD_STATUS_MESSAGES[lead.status].title);
    setMessage(leadConstants.LEAD_STATUS_MESSAGES[lead.status].message);


  }, [lead]);

  return (
    <Fragment >
      <AppBar color="default" position="sticky">
        <Toolbar>
          <StatusInfo lead={lead}
            title={title}
            message={message} />
          <span className={classes.spacer} />
          {buttons()}
        </Toolbar>
      </AppBar>
      <LeadActionModal
        lead={lead}
        defaultMessage={defaultMessage}
        open={confirmOpened}
        actions={confirmAction}
        leadStatus={lead && lead.status}
        handleAction={handleConfirmAction}
        handleClose={handleClose}></LeadActionModal>
        
    </Fragment>

  );
};

LeadActions.defaultProps = {
  onStatusChange: function (e, status) { }
}

LeadActions.propTypes = {
  className: PropTypes.string,
  onStatusChange: PropTypes.func,
};

export default LeadActions;
