import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress
} from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import ZnCountrySelect from 'common/znFields/ZnCountrySelect';
import { ZnTags } from 'common/znFields/ZnTags';

const schema = {

  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 150
    }
  }
};

const defaultFormSchema = {
  loaded: false,
  isValid: false,
  values: {
    name: '',
    surname: '',
    phone: '',
    email: '',
    countryCode: '',
    company: '',
    departments: [],
    privacy: false,
    tags: [],

  },
  touched: {},
  errors: {}
};

const Edit = props => {
  const { open, setOpen, item, actions, reducer } = props;

  const doingAction = reducer.doingAction;
  const successAction = reducer.successAction;
  const actionErrorMsg = reducer.actionErrorMsg;

  const dispatch = useDispatch();

  const handleChange = event => {
    event.persist();
    handleFieldChange(
      event.target.name,
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    );
  };

  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };



  const tagsHandleChange = (values) => {
    handleFieldChange('tags', values);
  };

  const countryHandleChange = (event, values) => {
    handleFieldChange('countryCode', values ? values.code : "");
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));

    dispatch(actions.save(formState.values)).then((res, err)=> {
      // alert(JSON.stringify(err));
    });
  };


  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(defaultFormSchema);
    }
  };
  const [formState, setFormState] = useState(defaultFormSchema);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  };

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    if (item) {
      setFormState({
        ...formState,
        values: item
      });
    } else {
      setFormState(defaultFormSchema);
    }
  }, [item, open]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {}
    }));
  }, [actionErrorMsg]);

  const hasError = field => {
    if (!formState.errors) return false;
    if (!formState.errors[field]) return false;
    return formState.errors[field] ? true : false;
  };

  const getError = field => {
    if (undefined === formState) return null;
    if (undefined === formState.errors) return null;
    if (undefined === formState.errors[field]) return null;

    return formState.errors[field] && formState.errors[field][0];
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {item ? 'Edit customer' : 'New customer'}
        </DialogTitle>
        <DialogContent>
          {doingAction && (
            <LinearProgress></LinearProgress>
          )}
          <ZnTextField
            onKeyPress={handleSubmit}
            name="email"
            label="Email"
            onChange={handleChange}
            value={formState.values.email || ''}
            error={hasError('email')}
            helperText={getError('email')}
            margin="normal"
            variant="outlined"
          />
          <FormControlLabel required control={<Checkbox
            name="privacy"
            onChange={handleChange}
            checked={formState.values.privacy}
            value={1}
            color="primary" />} label="Privacy Mkt" />
          <ZnTags
            defaultValue={formState.values.tags || []}
            onChange={tagsHandleChange}
          ></ZnTags>

          <ZnTextField
            onKeyPress={handleSubmit}
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ''}
            error={hasError('name')}
            helperText={getError('name')}
            margin="normal"
          />
          <ZnTextField
            onKeyPress={handleSubmit}
            name="surname"
            label="Surname"
            onChange={handleChange}
            value={formState.values.surname}
            error={hasError('surname')}
            helperText={getError('surname')}
            margin="normal"
            variant="outlined"
          />

          <ZnTextField
            onKeyPress={handleSubmit}
            name="phone"
            label="Phone"
            onChange={handleChange}
            value={formState.values.phone || ''}
            error={hasError('phone')}
            helperText={getError('phone')}
            margin="normal"
            variant="outlined"
          />



          <ZnCountrySelect
            id="countryCode"
            label="Country"
            onChange={countryHandleChange}
            onKeyPress={handleSubmit}
            value={formState.values.countryCode}
            error={hasError('countryCode')}
            helperText={
              hasError('countryCode') ? formState.errors.countryCode[0] : null
            }
          />
          <ZnTextField
            onKeyPress={handleSubmit}
            name="company"
            label="Company"
            onChange={handleChange}
            value={formState.values.company || ''}
            error={hasError('company')}
            helperText={getError('company')}
            margin="normal"
            variant="outlined"
          />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};

Edit.defaultProps = {
  open: false,
  item: {}
};
export default Edit;
