import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  Hidden,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { configActions } from '_actions/config.actions';


const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    

  },
  content: {
    [theme.breakpoints.down('md')]: {
      padding:0,
    },
    
  },
  number: {
    display: 'flex',
    alignItems: 'center',
    color: "white",
    fontWeight: "bold",
    [theme.breakpoints.down('md')]: {
      padding:0,
    },
  },
  numberText : {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },

  }


}));


const SummaryBox = props => {
  const { title,subtitle,  total, link, statuses, color, icon } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  
  const handleClick = event => {
    dispatch(
      configActions.setLeadSearchStatuses(statuses)
    );
  };

  const leadSearchStatuses = useSelector(state => state.config.leadSearchStatuses);

  const isActive =() => {

    if ( JSON.stringify(leadSearchStatuses) == JSON.stringify(statuses) ) {
      return true;
    }
    return false;
  }


  return (

    <Card className={classes.root} style={ isActive() ? { backgroundColor: color } : {}}>
      <Button
        style={{ textAlign:"left", width: "100%" }}
        
        variant="text"
        onClick={handleClick}

      >
        <CardContent className={classes.content}>
          <Typography style={ isActive() ? {color: '#ffffff'}: { }}>
          {icon }
          </Typography>
        </CardContent>
        <Hidden mdDown>
          <div className={classes.details}>
            <CardContent className={classes.content}>

              
              <Typography component="h5" variant="h5"
              style={ isActive() ? {color: '#ffffff'}: { }}
              >{title}</Typography>

              <Typography variant="subtitle2" color="textSecondary" 
              style={ isActive() ? {color: '#ffffff'}: { }}
              >{subtitle}</Typography>
              
            </CardContent>

          </div>
        </Hidden> 
        <CardContent className={classes.number} >
          <Typography  className={classes.numberText} component="h3" variant="h3" style={ isActive() ? {color: '#ffffff'}: { color: color }}>
            {total}
          </Typography>
        </CardContent>
      </Button>
    </Card>

  );
};

SummaryBox.propTypes = {
  className: PropTypes.string
};

export default SummaryBox;
