export const leadConstants = {


    SEND_MAIL_REQUEST: 'LEADS_SENDMAIL_REQUEST',
    
    GETOVERVIEW_REQUEST: 'LEADS_GETOVERVIEW_REQUEST',
    GETOVERVIEW_SUCCESS: 'LEADS_GETOVERVIEW_SUCCESS',
    GETOVERVIEW_FAILURE: 'LEADS_GETOVERVIEW_FAILURE',

    GETALL_REQUEST: 'LEADS_GETALL_REQUEST',
    GETALL_SUCCESS: 'LEADS_GETALL_SUCCESS',
    GETALL_FAILURE: 'LEADS_GETALL_FAILURE',

    GETONE_REQUEST: 'LEADS_GETONE_REQUEST',
    GETONE_SUCCESS: 'LEADS_GETONE_SUCCESS',
    GETONE_FAILURE: 'LEADS_GETONE_FAILURE',

    UPDATE_REQUEST: 'LEADS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LEADS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LEADS_UPDATE_FAILURE',

    SEND_MESSAGE_REQUEST: 'LEADS_SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_SUCCESS: 'LEADS_SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE: 'LEADS_SEND_MESSAGE_FAILURE',

    ADD_DOCUMENT_REQUEST: 'LEADS_ADD_DOCUMENT_REQUEST',
    ADD_DOCUMENT_SUCCESS: 'LEADS_ADD_DOCUMENT_SUCCESS',
    ADD_DOCUMENT_FAILURE: 'LEADS_ADD_DOCUMENT_FAILURE',

    LEAD_ACTION_SET_PREVIOUS_STATUS: 'LEAD_ACTION_SET_PREVIOUS_STATUS',
    LEAD_ACTION_SET_AS_SPAM: 'LEAD_ACTION_SET_AS_SPAM',
    LEAD_ACTION_SET_AS_NEW_MESSAGE: 'LEAD_ACTION_SET_AS_NEW_MESSAGE',
    LEAD_ACTION_SET_AS_NEW_LEAD: 'LEAD_ACTION_SET_AS_NEW_LEAD',
    LEAD_ACTION_SET_AS_CHECKED: 'LEAD_ACTION_SET_AS_CHECKED',
    LEAD_ACTION_SET_AS_FIRST_CONTACT: 'LEAD_ACTION_SET_AS_FIRST_CONTACT',
    LEAD_ACTION_SET_AS_OK: 'LEAD_ACTION_SET_AS_OK',
    LEAD_ACTION_SET_AS_KO: 'LEAD_ACTION_SET_AS_KO',
    LEAD_ACTION_SET_AS_ERP: 'LEAD_ACTION_SET_AS_ERP',
    LEAD_ACTION_SET_AS_ERP_COMPLETED: 'LEAD_ACTION_SET_AS_ERP_COMPLETED',
    LEAD_ACTION_ADD_NOTE: 'LEAD_ACTION_ADD_NOTE',
    LEAD_ACTION_ADD_DOCUMENT: 'LEAD_ACTION_ADD_DOCUMENT',
    LEAD_ACTION_SAVE: 'LEAD_ACTION_SAVE',
    LEAD_ACTION_SEND_MESSAGE : 'LEAD_ACTION_SEND_MESSAGE',



    LEAD_ACTION_MESSAGES: {
        LEAD_ACTION_SET_PREVIOUS_STATUS: { 
            "title": "BACK TO PREVIOUS STATUS", 
            "message": "Do you want to mark this message as spam?" 
        },
        LEAD_ACTION_SET_AS_SPAM: { 
            "title": "IS SPAM", 
            //"message": "Do you want to mark this message as spam?" 
        },
        LEAD_ACTION_SET_AS_NEW_MESSAGE: { 
            "title": "RESET TO NEW MESSAGE", 
            "message": "Do you want to reset this message to new?" 
        },
        LEAD_ACTION_SET_AS_NEW_LEAD: { 
            "title": "IS A LEAD", 
            "message": "" 
        },
        LEAD_ACTION_SET_AS_CHECKED: { 
            "title": "CHECKED", 
            "message": "Do you want to mark this lead as a checked?" 
        },
        LEAD_ACTION_SET_AS_KO: {
            "title": "NOT INTERESTED",
        },
        LEAD_ACTION_SET_AS_OK: {
            "title": "CONVERTED",
        },
        LEAD_ACTION_SET_AS_ERP: {
            "title": "LOADED INTO ELIBRA",
        },
        LEAD_ACTION_SET_AS_ERP_COMPLETED: {
            "title": "MATTER COMPLETED",
        },
        LEAD_ACTION_ADD_NOTE: { 
            "title": "", 
            "message": "" 
        }
    },





    LEAD_STATUS_NEW_MESSAGE: 'LEAD_STATUS_NEW_MESSAGE',
    LEAD_STATUS_SPAM: 'LEAD_STATUS_SPAM',
    LEAD_STATUS_DROPPED: 'LEAD_STATUS_DROPPED',
    LEAD_STATUS_AUTO_CLOSED: 'LEAD_STATUS_AUTO_CLOSED',
    LEAD_STATUS_AUTO_SPAM: 'LEAD_STATUS_AUTO_SPAM',
    LEAD_STATUS_AUTO_REPLY: 'LEAD_STATUS_AUTO_REPLY',
    LEAD_STATUS_NEW: 'LEAD_STATUS_NEW',
    LEAD_STATUS_CHECKED: 'LEAD_STATUS_CHECKED',
    LEAD_STATUS_FIRST_CONTACT: 'LEAD_STATUS_FIRST_CONTACT',
    LEAD_STATUS_OK: 'LEAD_STATUS_OK',
    LEAD_STATUS_KO: 'LEAD_STATUS_KO',
    LEAD_STATUS_ERP: 'LEAD_STATUS_ERP',
    LEAD_STATUS_ERP_COMPLETED: 'LEAD_STATUS_ERP_COMPLETED',

    LEAD_STATUS_COLORS: {
        NEW: '#BC2957',
        PROCESSING: '#999999',
        CONVERTED: '#FCBC05',
        ERP: '#43AE61',
    },


    LEAD_STATUS_MESSAGES: {
        LEAD_STATUS_NEW_MESSAGE: {
            process_color: "#BC2957",
            process_status: "New",
            process_action: "Should be managed asap.",
            short: "NEW", 
            title: 'New',
            message: 'Check if is Spam or is a Lead.',
            actions: ["LEAD_ACTION_SET_AS_SPAM", "LEAD_ACTION_SET_AS_NEW_LEAD"]
        },
        LEAD_STATUS_SPAM: {
            process_color: "#999999",
            process_status: "Spam",
            process_action: "No action required.",
            short: "SPAM", 
            title: 'This is Spam.',
            message: 'Just Spam...nothing to do... but, if you want,you can set it back to NEW MESSAGE.',
            actions: ["LEAD_ACTION_SET_AS_NEW_MESSAGE"]
        },
        LEAD_STATUS_DROPPED: {
            process_color: "#999999",
            process_status: "Spam",
            process_action: "No action required.",
            short: "SPAM", 
            title: 'This is Spam.',
            message: 'Just Spam...nothing to do... but, if you want,you can set it back to NEW MESSAGE.',
            actions: ["LEAD_ACTION_SET_AS_NEW_MESSAGE"]
        },
        LEAD_STATUS_AUTO_SPAM: {
            process_color: "#999999",
            process_status: "Auto Spam",
            process_action: "No action required.",
            short: "AUTO_SPAM", 
            title: 'This is AutoSpam.',
            message: 'Autospam automatically detected by system.',
            actions: ["LEAD_ACTION_SET_AS_NEW_MESSAGE"]
        },
        LEAD_STATUS_AUTO_CLOSED: {
            process_color: "#999999",
            process_status: "Auto Closed",
            process_action: "No action required.",
            short: "AUTO_CLOSED", 
            title: 'This lead is AutoClosed.',
            message: 'Auto closed by system after N days.',
            actions: ["LEAD_ACTION_SET_AS_NEW_MESSAGE"]
        },
        LEAD_STATUS_AUTO_REPLY: {
            process_color: "#999999",
            process_status: "Auto Reply",
            process_action: "No action required.",
            short: "AUTO_REPLY", 
            title: 'This is AutoReply.',
            message: 'Auto reply automatically detected by system.',
            actions: ["LEAD_ACTION_SET_AS_NEW_MESSAGE"]
        },
        LEAD_STATUS_NEW: {
            process_color: "#BC2957",
            process_status: "New",
            process_action: "Should be managed asap.",
            short: "NEW", 
            title: 'New Lead',
            message: 'Check and save the Lead data in the form.',
            actions: ["LEAD_ACTION_SET_AS_CHECKED"]
        },
        LEAD_STATUS_CHECKED: {
            process_color: "#BC2957",
            process_status: "New",
            process_action: "Should be managed asap.",
            short: "CHECKED", 
            title: 'Checked',
            message: 'Send the reply email to the lead.',
            actions: []

        },
        LEAD_STATUS_FIRST_CONTACT: {
            process_color: "#999999",
            process_status: "Processing",
            process_action: "Work in progress...",
            short: "PROC", 
            title: 'Processing',
            message: 'First reply sent.You can add Notes, or close the Lead.',
            actions: ["LEAD_ACTION_SET_AS_KO", "LEAD_ACTION_SET_AS_OK"]
        },
        LEAD_STATUS_OK: {
            process_color: "#FCBC05",
            process_status: "Converted",
            process_action: "To be loaded into Elibra.",
            short: "OK", 
            title: 'Converted',
            message: 'Lead needs to be loaded into Elibra.',
            actions: ['LEAD_ACTION_SET_AS_ERP']
        },
        LEAD_STATUS_KO: {
            process_color: "#999999",
            process_status: "Ko",
            process_action: "Next lead will be the right one.",
            short: "KO", 
            title: 'Not converted',
            message: 'Lead not converted.',
            actions: []
        },
        LEAD_STATUS_ERP: {
            process_color: "#43AE61",
            process_status: "Elibra",
            process_action: "payment received",
            short: "ELIBRA", 
            title: 'Loaded into Elibra',
            message: 'Matter needs to be set as completed in Elibra.',
            actions: ['LEAD_ACTION_SET_AS_ERP_COMPLETED']
        },
        LEAD_STATUS_ERP_COMPLETED: {
            process_color: "#43AE61",
            process_status: "Matter Completed",
            process_action: "Everything has done.",
            short: "MATTER COMPLETED", 
            title: 'Matter Completed',
            message: '',
            actions: []
        },
        
    }
};
