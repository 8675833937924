import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader, Paper } from '@material-ui/core';
import Typography from 'theme/typography';



const LeadsByCountryAndDept = props => {
    const { series = [], categories = [], name = '', withJurisdiction = 0, withoutJurisdiction = 0 } = props;

    const cartData = {
        options: {
            chart: {
                type: 'donut',
                id: "LeadsByCountryAndDept",
            },
            xaxis: {
                categories: categories
            },
            labels: categories,
            colors: [
                '#008FFB',
                '#00E396',
                '#FEB019',
                '#FF4560',
                '#775DD0',
                '#3f51b5',
                '#4caf50',
                '#ffe986',
                '#f9a3a4',
                '#A300D6',
            ],
        },
        series: series
    };

    const chartMiniData = {
        options: {
            chart: {
                type: 'donut',
                id: "Jurisdicitoned",
            },
            xaxis: {
                categories: ['With Jurisdicion', 'Without Jurisdicion']
            },
            labels: ['With Jurisdicion', 'Without Jurisdicion'],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: ['#008000', '#CCC'],
            stroke: {
                show: false 
            }
        },
        series: [withJurisdiction, withoutJurisdiction]
    };

    return (categories && series) ?
        <Card
        style={{
            overflow: 'visible'
        }}>
            <CardHeader title={name}
                subheader="All incoming leads by country">
            </CardHeader>
            <CardContent
                style={{
                    position: 'relative'
                }}
            >
                {
                    cartData.series.length > 0 ? (
                        <ReactApexChart
                            type="donut"
                            options={cartData.options}
                            series={cartData.series}
                            height={350}
                        />
                    ) : (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height={350}
                        >
                            <Box>no data</Box>
                        </Box>
                    )

                }
                {
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '10px',
                            width: '49px',
                            height: '49px',
                            backgroundColor: '#f2f2f2',
                            padding: '10px',
                            borderRadius: '50%',
                            boxShadow: 'inset 0 0 5px rgba(0,0,0,0.1)'
                        }}
                    >

                        <ReactApexChart
                            type="donut"
                            options={chartMiniData.options}
                            series={chartMiniData.series}
                            width={70}
                            height={70}
                            style={{
                                marginLeft:'-21px',
                                marginTop:'-8px'
                            }}
                        />
                    </Box>
                }

            </CardContent>
        </Card> : <>loading</>

};

LeadsByCountryAndDept.propTypes = {
    series: PropTypes.array,
    categories: PropTypes.array,
};

export default LeadsByCountryAndDept;
