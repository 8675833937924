import { configConstants, leadConstants } from '../_constants';

const initialStatus = {
  departmentId: 0,
  userId: 'none',
  activeSearchBox: 'lead',

  leadReload: false,
  leadSearchPage: 0,
  leadSearchRowsPerPage: 50,

  newsletterReload: false,
  newsletterSearchPage: 0,
  newsletterSearchRowsPerPage: 50,


  surveyReload: false,
  surveySearchPage: 0,
  surveySearchRowsPerPage: 50,

  leadSearchText: '',
  leadSearchEmail: '',
  leadSearchPhone: '',
  leadSearchSubject: '',
  leadSearchNotes: '',

  leadSearchStatuses: [
    leadConstants.LEAD_STATUS_NEW_MESSAGE,
    leadConstants.LEAD_STATUS_NEW,
    leadConstants.LEAD_STATUS_CHECKED,

  ],

  messageSearchText: '',
  messageSearchStatuses: [
    leadConstants.LEAD_STATUS_NEW_MESSAGE,
  ],
};

export function config(state = initialStatus, action) {

  switch (action.type) {
    case configConstants.RESET_LEAD_SEARCH:
      return {
        ...initialStatus,
        leadReload: Math.random(),
      };
    case configConstants.SET_DEPARTMENT:
      return {
        ...state,
        departmentId: action.departmentId,
        leadReload: Math.random(),
      };
    case configConstants.SET_USER:
      return {
        ...state,
        userId: action.userId,
        leadReload: Math.random(),
      };

    case configConstants.SET_MESSAGE_SEARCH_TEXT:
      return {
        ...state,
        messageSearchText: action.messageSearchText
      };
    case configConstants.SET_MESSAGE_SEARCH_STATUSES:
      return {
        ...state,
        messageSearchStatuses: action.messageSearchStatuses
      };

    case configConstants.SET_LEAD_SEARCH_TEXT:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchText: action.leadSearchText,
        leadReload: Math.random(),
        leadSearchStatuses: [
          leadConstants.LEAD_STATUS_ERP,
          leadConstants.LEAD_STATUS_ERP_COMPLETED,
          leadConstants.LEAD_STATUS_CHECKED,
          leadConstants.LEAD_STATUS_FIRST_CONTACT,
          leadConstants.LEAD_STATUS_KO,
          leadConstants.LEAD_STATUS_MESSAGES,
          leadConstants.LEAD_STATUS_NEW,
          leadConstants.LEAD_STATUS_NEW_MESSAGE,
          leadConstants.LEAD_STATUS_OK,
          leadConstants.LEAD_STATUS_SPAM,
          leadConstants.LEAD_STATUS_AUTO_SPAM,
          leadConstants.LEAD_STATUS_AUTO_REPLY,
          leadConstants.LEAD_STATUS_AUTO_CLOSED,
        ],
      };
    case configConstants.SET_LEAD_SEARCH_TEXTS:
      return {
        ...state,
        leadSearchPage: 0,
        leadReload: Math.random(),
        ...action.leadSearchTexts

      };
    case configConstants.SET_LEAD_SEARCH_EMAIL:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchEmail: action.leadSearchEmail,
        leadReload: Math.random(),

      };
    case configConstants.SET_LEAD_SEARCH_PHONE:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchPhone: action.leadSearchPhone,
        leadReload: Math.random(),

      };

    case configConstants.SET_LEAD_SEARCH_SUBJECT:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchSubject: action.leadSearchSubject,
        leadReload: Math.random(),

      };

    case configConstants.SET_LEAD_SEARCH_NOTES:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchNotes: action.leadSearchNotes,
        leadReload: Math.random(),

      };

    case configConstants.SET_LEAD_SEARCH_STATUSES:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchStatuses: action.leadSearchStatuses,
        leadReload: Math.random(),
      };

    case configConstants.SET_LEAD_SEARCH_DATES:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchDatesFrom: action.from,
        leadSearchDatesTo: action.to,
        leadReload: Math.random(),
      };

    case configConstants.SET_LEAD_SEARCH_DATE_FROM:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchDatesFrom: action.from,
        leadReload: Math.random(),
      };

    case configConstants.SET_LEAD_SEARCH_DATE_TO:
      return {
        ...state,
        leadSearchPage: 0,
        leadSearchDatesTo: action.to,
        leadReload: Math.random(),
      };


    case configConstants.SET_LEAD_PAGE:
      return {
        ...state,
        leadSearchPage: action.page,
        leadReload: Math.random(),
      };
    case configConstants.SET_LEAD_ROWS_PER_PAGE:
      return {
        ...state,
        leadSearchPage: initialStatus.leadSearchPage,
        leadSearchRowsPerPage: action.rowsPerPage,
        leadReload: Math.random(),
      };

    case configConstants.SET_ACTIVE_SEARCH_BOX:
      return {
        ...state,
        activeSearchBox: action.activeSearchBox,
      };

    case configConstants.SET_NEWSLETTER_PAGE:
      return {
        ...state,
        newsletterSearchPage: action.page,
        newsletterReload: Math.random(),
      };

    case configConstants.SET_NEWSLETTER_ROWS_PER_PAGE:
      return {
        ...state,
        newsletterSearchPage: initialStatus.leadSearchPage,
        newsletterSearchRowsPerPage: action.rowsPerPage,
        newsletterReload: Math.random(),
      };

      case configConstants.SET_SURVEY_PAGE:
        return {
          ...state,
          surveySearchPage: action.page,
          surveyReload: Math.random(),
        };
  
      case configConstants.SET_SURVEY_ROWS_PER_PAGE:
        return {
          ...state,
          surveySearchPage: initialStatus.surveySearchPage,
          surveySearchRowsPerPage: action.rowsPerPage,
          surveyReload: Math.random(),
        };
    default:
      return state;
  }
}