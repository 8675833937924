/* eslint-disable no-use-before-define */
import React from 'react';
import ZnSelect from './ZnSelect';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { sourceActions, sourceFormActions, sourceWebsiteActions } from '_actions';
import theme from 'theme';

export {
    ZnSourceSelect,
    ZnSourceWebsiteSelect,
    ZnSourceWebsiteFormSelect,
    ZnSourceFormSelect,
    ZnSimpleSourceSelect,
    ZnSimpleSourceWebsiteSelect,
}

const ZnSourceSelect = (props) => {

    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText
    } = props;


    const data = useSelector(state => state.source.items)
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        dispatch(sourceActions.getAll());
    }, []);

    useEffect(() => {
        if (!Array.isArray(data)) return;
        setItems([
            { code: "", label: "All" },
            ...data.map(x => { return { code: x.id, label: x.name }; })
        ]);
    }, [data]);

    return (
        <ZnSelect
            id={id}
            label={label}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            error={error}
            helperText={helperText}
            items={items}
        />
    );
}

const ZnSourceWebsiteSelect = (props) => {

    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText
    } = props;


    const data = useSelector(state => state.sourceWebsite.items)
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        dispatch(sourceWebsiteActions.getAll());
    }, []);

    useEffect(() => {
        if (!Array.isArray(data)) return;
        setItems([
            { code: "", label: "All" },
            ...data.map(x => { return { code: x.id, label: x.name }; })
        ]);
    }, [data]);

    return (
        <ZnSelect
            id={id}
            label={label}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            error={error}
            helperText={helperText}
            items={items}
        />
    );
}
const ZnSourceWebsiteFormSelect = (props) => {
    // usata nei filtri delle Analitiche
    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText
    } = props;


    const data = useSelector(state => state.sourceForm.items)
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        dispatch(sourceFormActions.getAll());
    }, []);

    useEffect(() => {
        if (!Array.isArray(data)) return;
        setItems(data.map(x => {
            return { code: x.id, label: x.name };
        }));
    }, [data]);

    return (
        <ZnSelect
            id={id}
            label={label}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            error={error}
            helperText={helperText}
            items={items}
        />
    );
}

const ZnSourceFormSelect = (props) => {

    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText
    } = props;

    const classes = simpleSelectUseStyles();

    const items = useSelector(state => state.sourceForm.items);
    const [currentItem, setCurrentItem] = useState();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sourceFormActions.getAll());
    }, []);


    const handleChange = event => {
        setCurrentItem(event.target.value);
    };

    useEffect(() => {
        onChange(currentItem);
    }, [currentItem]);

    return (

        <Select
            classes={{
                root: classes.root,
                select: classes.select,
                icon: classes.icon,
            }}
            value={currentItem}
            onChange={handleChange}
            style={{ width: "100%" }}
        >
            <MenuItem value="">All forms</MenuItem>
            {items.map((item, k) => {

                if (item.id == 0) return;

                return (
                    <MenuItem key={k} value={item.id}>#{item.id} {item.full_name}</MenuItem>
                );

            })}
        </Select>

    );
}


const simpleSelectUseStyles = makeStyles({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        // color: 'white',
    },
    select: {
        // color: 'inerith'

    },
    icon: {
        // color: 'white'
    },
})


const ZnSimpleSourceWebsiteSelect = (props) => {

    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText
    } = props;

    const classes = simpleSelectUseStyles();

    const items = useSelector(state => state.sourceWebsite.items);
    const [currentItem, setCurrentItem] = useState();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sourceWebsiteActions.getAll());
    }, []);


    const handleChange = event => {
        setCurrentItem(event.target.value);
    };

    useEffect(() => {
        onChange(currentItem);
    }, [currentItem]);

    return (

        <Select
            classes={{
                root: classes.root,
                select: classes.select,
                icon: classes.icon,
            }}
            value={currentItem}
            onChange={handleChange}
            style={{ width: "100%" }}
        >
            <MenuItem value="">All websites</MenuItem>
            {items.map((item, k) => {

                if (item.id == 0) return;

                return (
                    <MenuItem key={k} value={item.id}>#{item.id} {item.full_name}</MenuItem>
                );

            })}
        </Select>

    );
}


const ZnSimpleSourceSelect = (props) => {

    const {
        id,
        label,
        onChange,
        onKeyPress,
        value,
        error,
        helperText,
        fullWidth = false,
        withPlaceholder = false
    } = props;

    const classes = simpleSelectUseStyles();

    const items = useSelector(state => state.source.items);
    const [currentItem, setCurrentItem] = useState('');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sourceActions.getAll());
    }, []);


    const handleChange = event => {
        setCurrentItem(event.target.value);
    };

    useEffect(() => {
        onChange && onChange(currentItem);
    }, [currentItem]);

    useEffect(() => {
        if (value == null) {
            setCurrentItem('')
            return
        }
        if (value === undefined) return
        if (value == currentItem) return
        setCurrentItem(value)

    }, [value])

    return (

        <FormControl fullWidth={fullWidth} className={withPlaceholder && classes.formControl}>
            {withPlaceholder && (
                <InputLabel id="source-simple-select-label">Source</InputLabel>
            )}
            <Select
                labelId='source-simple-select-label'
                classes={{
                    root: classes.root,
                    select: classes.select,
                    icon: classes.icon,
                }}
                displayEmpty
                value={currentItem}
                onChange={handleChange}
                fullWidth={fullWidth}
            >
                <MenuItem value="">All Sources</MenuItem>
                {items.map((item, k) => {

                    if (item.id == 0) return;

                    return (
                        <MenuItem key={k} value={item.id}>#{item.id} {item.full_name}</MenuItem>
                    );

                })}
            </Select>
        </FormControl>

    );
}



