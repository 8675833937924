import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import TotalRadial from '../TotalRadial';
import { leadConstants } from '_constants';


const Recap = (props) => {


    const {
        filters,
        loading,
        received,
        spam,
        autospam,
        managed,
        converted,
        elibra,
        received_perc,
        spam_perc,
        autospam_perc,
        managed_perc,
        converted_perc,
        elibra_perc,
    } = props;


    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        console.log('setLoaded', loading)
        if (loading === false) {
            setLoaded(true);
        }
    }, [loading]);



    return (
        <>
            <Grid
                container
                spacing={4}
            >
               
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial
                        loaded={loaded} value={received} percent={received_perc} color="#1e90ff" title="Received" 
                        // fontSizeName="40px" 
                        // fontSizeValue="18px"
                        ></TotalRadial>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial href="/dashboard" hrefQs={{ ...filters, status: leadConstants.LEAD_STATUS_SPAM }}
                        loaded={loaded} value={spam} percent={spam_perc} color="#BC2957" title="Spam (% related to Received)"></TotalRadial>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial href="/dashboard" hrefQs={{ ...filters, status: leadConstants.LEAD_STATUS_AUTO_SPAM }}
                        loaded={loaded} value={autospam} percent={autospam_perc} color="#BC2957" title="AutoSpam (% related to Received)" ></TotalRadial>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial href="/dashboard" hrefQs={{ ...filters, status: leadConstants.LEAD_STATUS_FIRST_CONTACT }}
                        loaded={loaded} value={managed} percent={managed_perc} color="#b9bc29" title="Processed (% related to Received)"></TotalRadial>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial href="/dashboard" hrefQs={{ ...filters, status: leadConstants.LEAD_STATUS_OK }}
                        loaded={loaded} value={converted} percent={converted_perc} color="#29bc37" title="Converted (% related to Processed)"></TotalRadial>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                    <TotalRadial href="/dashboard" hrefQs={{ ...filters, status: leadConstants.LEAD_STATUS_ERP }}
                        loaded={loaded} value={elibra} percent={elibra_perc} color="#29bc37" title="Loaded Into Elibra (% related to Processed)"></TotalRadial>
                </Grid>
            </Grid>
        </>
    );
};


export default Recap;
