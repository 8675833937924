import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';



const PerformanceByDepartments = props => {
    const { series = [], categories = [], title, subheader } = props;

    const valFormatter = (val, syntetic = false) => {
        let minutesRest = parseInt(val % 60);
        let hours = parseInt(val / 60);
        let hoursRest = parseInt(hours % 24);
        let days = parseInt(hours / 24);
        let final;

        if (syntetic) {
            if (days > 0) {
                final = Math.round(val / 1440) + 'd';
            } else {
                final = Math.round(val / 60) + 'h';
            }
        } else {
            final = `${hoursRest}h ${minutesRest}m`;
            if (days > 0) final = `${days}d ` + final;
        }
        return final;
    }

    const cartData = {
        options: {
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#FFF'],
                    fontWeight: 700,
                },
                formatter: (val) => {
                    return valFormatter(val, true);
                }
            },
            chart: {
                type: 'bar',
                stacked: true,
                id: "PerformanceByDepartments",

            },
            plotOptions: {
                bar: {
                    borderRadius: '15',
                    dataLabels: {
                        position: 'top',
                    },
                    barHeight: '90%',
                    // distributed: true,
                    horizontal: true
                },
            },

            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    formatter: (val) => {
                        const valInt = parseInt(val / 60 / 24);
                        return `${valInt}`
                    }
                }
            },
            yaxis: {

            },
            tooltip: {
                y: {
                    formatter: (val) => {
                        return valFormatter(val);
                    }
                }
            }
        },
        series: series
    };


    return (categories && series) ?
        <Card>
            <CardHeader
                title={title}
                subheader={subheader}>

            </CardHeader>
            <CardContent>
                <ReactApexChart
                    type="bar"
                    options={cartData.options}
                    series={cartData.series}
                // height={1750} 
                />
            </CardContent>
        </Card> : <>loading</>

};

PerformanceByDepartments.propTypes = {
    series: PropTypes.array,
    categories: PropTypes.array,
};

export default PerformanceByDepartments;
