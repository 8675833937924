import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Box, Typography } from '@material-ui/core';
import { SidebarNav } from './components';
import { useLocation } from 'react-router';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { BusinessIcon, DashboardIcon, FilterIcon } from 'icons';
import { PeopleIcon } from 'icons';
import { TagIcon } from 'icons';

import { FaceIcon } from 'icons';
import { MessageModelIcon } from 'icons';
import { AttachmentIcon } from 'icons';
import { AssessmentIcon } from 'icons';
import { SurveyIcon } from 'icons';
import { ProcedureIcon } from 'icons';
import { NewsletterIcon } from 'icons';
import { SettingsIcon } from 'icons';
import { MessageIcon } from 'icons';
import { InputIcon } from 'icons';
import { WebsiteIcon } from 'icons';
import { SourceIcon } from 'icons';
import { FormIcon } from 'icons';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,

    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      height: 'calc(100%)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  currentPageName: {
    paddingLeft: theme.spacing(2)
  }
}));

const pagesUser = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Emails',
    href: '/messages',
    icon: <MessageIcon />
  },

  {
    title: 'Settings',
    href: '/settings',
    icon: <SettingsIcon />
  },
  {
    title: 'Logout',
    href: '/sign-in',
    icon: <InputIcon />
  }


];

const pagesContent = [
  {
    title: 'Message Models',
    href: '/message-model',
    icon: <MessageModelIcon />
  },
  {
    title: 'Attachments',
    href: '/attachment',
    icon: <AttachmentIcon />
  },
  {
    title: 'Procedures',
    href: '/procedure',
    icon: <ProcedureIcon />
  },
];

const pagesArchives = [
  // {
  //   title: 'Analytics',
  //   href: '/analytics',
  //   icon: <AssessmentIcon />
  // },
  {
    title: 'Lead Received',
    href: '/analytics2',
    icon: <AssessmentIcon />
  },
  {
    title: 'Lead Converted',
    href: '/analytics3',
    icon: <AssessmentIcon />
  },
  {
    title: 'Performance',
    href: '/analytics4',
    icon: <AssessmentIcon />
  },
  {
    title: 'Survey',
    href: '/survey',
    icon: <SurveyIcon />
  }


];

const pagesAdmin = [
  {
    title: 'Customers',
    href: '/customer',
    icon: <PeopleIcon />
  },
  {
    title: 'Tags',
    href: '/tag',
    icon: <TagIcon />
  },
  // {
  //   title: 'Companies',
  //   href: '/companies',
  //   icon: <BusinessIcon />
  // },
  // {
  //   title: 'Newsletter',
  //   href: '/newsletter',
  //   icon: <NewsletterIcon />
  // },
];

const pagesTools = [
  {
    title: 'Users',
    href: '/users',
    icon: <PeopleIcon />
  }
];

const sources = [
  {
    title: 'Sources',
    href: '/source',
    icon: <SourceIcon />
  },
  {
    title: 'Websites',
    href: '/source-website',
    icon: <WebsiteIcon />
  },
  {
    title: 'Forms',
    href: '/source-form',
    icon: <FormIcon />
  },
  // {
  //   title: 'Filters',
  //   href: '/source-filters',
  //   icon: <FilterIcon />
  // }
];

const Sidebar = props => {
  const { open, variant, onClose, onClick, className, ...rest } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Box mx="auto" mb="5px" mt="-8px">
          <img
            src="/logo-piccolo.png"
            width="150"></img>
        </Box>
        <Divider />



        <SidebarNav
          onClick={onClick}
          className={classes.nav}
          pages={pagesUser}
        />
        <SidebarNav
          title="Presets"

          onClick={onClick}
          className={classes.nav}
          pages={pagesContent}
        />
        <SidebarNav
          title="Analytics"
          onClick={onClick}
          className={classes.nav}
          pages={pagesArchives}
        />
        <SidebarNav
          title="Crm"

          onClick={onClick}
          className={classes.nav}
          pages={pagesAdmin}
        />
        <SidebarNav
          title="Sources"

          onClick={onClick}
          className={classes.nav}
          pages={sources} />
        <SidebarNav
          title="Users"
          onClick={onClick}
          className={classes.nav}
          pages={pagesTools}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const CurrentPageName = props => {
  const classes = useStyles();

  const location = useLocation();

  const page = pagesAdmin
    .concat(pagesUser)
    .concat(pagesArchives)
    .find(function (element) {
      return element.href === location.pathname;
    });

  return (
    <Typography
      variant="h5"
      color="inherit"
      className={classes.currentPageName}>
      {page && page.title}
    </Typography>
  );
};

export { Sidebar as default, CurrentPageName };
